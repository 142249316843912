<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reservas - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="9"></b-col>
              <b-col sm="12" md="1">
                  <b-button type="button" title="Exportar Excel" @click="ExportExcel" class="form-control" variant="success"><i class="fas fa-file-excel"></i></b-button>
              </b-col>
              <b-col sm="6" md="2">
                <b-input-group>
                  <b-form-input v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListReservation">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
      
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="5%" class="text-center">Nro Reserva</th>
                    <th width="40%" class="text-center">Nombre</th>
                    <th width="20%" class="text-center">email</th>
                    <th width="10%" class="text-center">Telefono</th>
                    <th width="5%" class="text-center">Total</th>
  
                    <th width="7%" class="text-center">Estado</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="item.id">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left">{{ item.number }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-left"> {{ item.email }}</td>
                    <td class="text-left"> {{ item.phone }}</td>
                    <td class="text-right"> {{ item.total }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 0" variant="danger">Agotado</b-badge>
                      <b-badge v-if="item.state == 1" variant="warning">Abandonado</b-badge>
                      <b-badge v-if="item.state == 2" variant="success">Realizado</b-badge>
                    </td>
                    <td class="text-center"> {{ item.created_at }}</td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <!-- <b-dropdown-item v-if="Permission('ReservationEdit')" @click="EditReservation(item.id_reservation)">Editar</b-dropdown-item> -->
                        <b-dropdown-item v-if="Permission('ReservationView')" @click="ViewReservation(item.id_reservation)">Ver</b-dropdown-item>
                       
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListReservation" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "UsuarioList",
  data() {
    return {
      module:'Reservation',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    this.ListReservation();
  },
  methods: {
    ListReservation,
    EditReservation,
    ViewReservation,
    ConfirmDeleteReservation,
    DeleteReservation,
    Permission,
    CodeDocumentType,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

//listar usuario
function ListReservation() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url =
    this.url_base + "reservation/list/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditReservation(id_reservation) {
  this.$router.push({
    name: "ReservationEdit",
    params: { id_reservation: je.encrypt(id_reservation) },
  });
}

// Ver Usuario
function ViewReservation(id_reservation) {
  this.$router.push({
    name: "ReservationView",
    params: { id_reservation: je.encrypt(id_reservation) },
  });
}
function ExportExcel() {  
  let url = this.url_base + "excel-reservations";
  window.open(url,'_blank');
}
// Confirmar eliminar
function ConfirmDeleteReservation(id_reservation) {
  Swal.fire({
    title: "Esta seguro de eliminar el cliente ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteReservation(id_reservation);
    }
  });
}

// eliminar usuario
function DeleteReservation(id_reservation) {
  let me = this;
  let url = this.url_base + "client/delete/" + id_reservation;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        //eliminado del objeto
        for (var i = 0; i < me.data_table.length; i++) {
          if (me.data_table[i].id_reservation == id_reservation) {
            me.data_table.splice(i, 1);
            break;
          }
        }
        Swal.fire("Eliminado!", "El cliente ha sido eliminado", "success");
      } else {
        Swal.fire(
          "A Ocurrido un error",
          "El registro no ha sido eliminado",
          "error"
        );
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
